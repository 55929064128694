/** @jsx jsx */
import { Container, Flex, Box, Heading, Text, jsx } from "theme-ui"
import { PageProps } from "gatsby"
import Layout from "components/Layout"
import PageHero from "components/PageHero"
import Arrow from "components/common/Arrow"
import BackgroundSVG from "images/icons/background-light.svg"

interface OnlineRetailersProps extends PageProps {
  className?: string
}

const ONLINE_RETAILERS = [
  {
    retailerName: "Brownells",
    retailerUrl: "https://prf.hn/l/2MjQ4ab",
  },
  {
    retailerName: "Midway USA",
    retailerUrl: "https://www.midwayusa.com/aguila/b?bid=2506",
  },
  {
    retailerName: "Sportsman's Guide",
    retailerUrl: "https://www.sportsmansguide.com/productlist/aguila?b=AGUILA",
  },
  {
    retailerName: "Ammo Planet",
    retailerUrl:
      "https://www.ammoplanet.com/brand/aguila?currentPage=1&facet_inStock=Yes&facet_productType=&pageSize=20",
  },
  {
    retailerName: "Target Sports USA",
    retailerUrl: "https://www.targetsportsusa.com/aguila-ammunition-m-132.aspx",
  },
  {
    retailerName: "Lucky Gunner",
    retailerUrl: "https://www.luckygunner.com/catalogsearch/result?q=aguila",
  },
  {
    retailerName: "Bud's Gun Shop",
    retailerUrl: "https://www.budsgunshop.com/search.php/manu/1038",
  },
  {
    retailerName: "Cheaper Than Dirt",
    retailerUrl:
      "https://www.cheaperthandirt.com/search.php?search_query=aguila",
  },
  {
    retailerName: "True Shot Ammo",
    retailerUrl: "https://trueshotammo.com/product-tag/aguila-ammunition/",
  },
  {
    retailerName: "Ammunition Depot",
    retailerUrl:
      "https://www.ammunitiondepot.com/search?q=aguila#/filter:ss_in_stock:In$2520Stock",
  },
  {
    retailerName: "Midsouth Shooters",
    retailerUrl:
      "https://www.midsouthshooterssupply.com/search?SearchTerm=Aguila+Ammunition",
  },
  {
    retailerName: "Optics Planet",
    retailerUrl:
      "https://www.opticsplanet.com/aguila-ammunition-ammunition.html",
  },
  {
    retailerName: "Natchez Shooting & Outdoors",
    retailerUrl: "https://www.natchezss.com/catalog/search/?query=aguila",
  },
  {
    retailerName: "Outdoor Limited",
    retailerUrl:
      "https://outdoorlimited.com/featured-brands/aguila/?cid=761&search_text=&per_row=20&sortBy=8&mode=list",
  },
]

const OnlineRetailers = ({ className, ...props }: OnlineRetailersProps) => {
  return (
    <Layout
      seo={{
        metaTitle: "Online Retailers",
        // metaDescription: "News & Events Page",
      }}
      location={props.location}
    >
      <PageHero
        title="Online Retailers"
        buttonOneText="Find a retailer near you"
        buttonOneUrl="/find-a-retailer"
        buttonOneVariant="textOnly"
        extraButtonInfo="or"
      />

      <Box
        as="section"
        sx={{
          bg: "darkBackground",
          color: "lightTan",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: 1,
            transformOrigin: "right",
            top: "-40%",
            right: "-40%",
            width: "auto",
            height: "180%",
            "& > g": {
              opacity: 0.125,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />
        <Container
          variant="wide"
          sx={{
            pt: [12, null, 24],
            pb: [24, null, 64],
            position: "relative",
            zIndex: 1,
          }}
        >
          <Heading sx={{ mb: 12 }}>Shop Online</Heading>
          <Box sx={{ variant: "layout.narrow" }}>
            <Box
              as="ul"
              sx={{
                listStyle: "none",
                m: 0,
                p: 0,
              }}
            >
              {ONLINE_RETAILERS.map((retailer, index) => (
                <Flex
                  key={index}
                  as="li"
                  sx={{
                    py: 5,
                    px: [5, null, null, 16],
                    borderBottomWidth: "1px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "lightTan",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Heading
                    variant="title"
                    sx={{
                      display: "inline-block",
                      position: "relative",
                      pl: 6,
                      "&::before": {
                        position: "absolute",
                        left: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        content: '""',
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        bg: "red",
                      },
                    }}
                  >
                    {retailer.retailerName}
                  </Heading>
                  <a
                    href={retailer.retailerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ variant: `buttons.textOnly` }}
                  >
                    <Text
                      as="span"
                      className="link-text"
                      sx={{ color: "lightTan", textTransform: "capitalize" }}
                    >
                      Visit Website
                    </Text>
                    <Arrow arrowColor={"primary"} />
                  </a>
                </Flex>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default OnlineRetailers
