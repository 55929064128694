/** @jsxRuntime classic */
/** @jsx jsx */
import { Flex, Box, Input, Label, jsx } from "theme-ui"
import AguilaButton from "components/AguilaButton"
import { useState } from "react"

interface RetailerLocatorProps {
  //enables sx prop to be passed down from parent
  className?: string
}

const RetailerLocator = ({ className }: RetailerLocatorProps) => {
  const [search, setSearch] = useState("")

  return (
    <Flex
      as="form"
      className={className}
      action="/find-a-retailer"
      // url
      sx={{
        alignItems: "center",
        position: "relative",
        zIndex: 10,
        bg: "lightTan",
        p: [4, null, 8],
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
        width: "100%",
        maxWidth: "622px",
        backgroundColor: "#E1251B",
      }}
    >
      <Box sx={{ flex: "1 1 70%", mr: 7, display: "flex" }}>
        <Box sx={{position: "relative", width: "100%", color: "white", display: "flex", whiteSpace: "nowrap"}}>
          <Input
            type="text"
            name="search"
            placeholder="Enter your city & state or ZIP code"
            value={search}
            onChange={e => setSearch(e.target.value)}
            sx={{
              fontSize: [0, null, 2, null, null],
              borderColor: "white",
              "@media screen and (max-width: 410px)": {
                fontSize: "10px",
              },
            }}
          />
          <Label
            htmlFor="search"
            sx={{
              fontSize: [0, null, 2, null, null],
              "@media screen and (max-width: 410px)": {
                fontSize: "10px",
              },
            }}
          >
            Enter your city & state or ZIP code
          </Label>
        </Box>
      </Box>
      <AguilaButton
        variant="secondary"
        text="Find a retailer"
        url={`/find-a-retailer?search=${search}`}
        sx={{ flex: "1 0 auto", mb: 2 }}
      />
    </Flex>
  )
}

export default RetailerLocator
